
import Vue from "vue";
import { signedPoliciesTableColumns } from "./SignedPoliciesTableColumns";
import { ITableWrapperColumns } from "@/types";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import {
  policySignMapActions,
  policySignMapGetters
} from "@/store/modules/policySign";

interface ISignedPoliciesData {
  errorMessage: string;
  loading: boolean;
  loadingText: string;
  tableColumns: ITableWrapperColumns;
  filters: { field: string; title: string; isDate?: boolean }[];
}

export default Vue.extend({
  name: "SignedPoliciesListView",
  components: {
    NoResultsFound,
    Exporter: () => import("@/components/Exporter/Exporter.vue")
  },
  data(): ISignedPoliciesData {
    return {
      errorMessage: "",
      loading: false,
      loadingText: "",
      tableColumns: signedPoliciesTableColumns,
      filters: [
        {
          field: "modifiedOn",
          title: "Signed On",
          isDate: true
        }
      ]
    };
  },
  methods: {
    ...policySignMapActions(["getSignedPolicies"]),
    async fetchPageData(queryObject: any): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Loading signed policies. Please wait...";
        this.$emit("scrollTop");
        await this.getSignedPolicies(queryObject);
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    tableActionHandler(action: string) {
      switch (action) {
        case "Export Signed Policies":
          this.$modal.show("exportPoliciesModal");
          break;
        case "Policy Sign Activities":
          this.$router.push(`/admin/policy-sign/activities`);
          break;
      }
    }
  },
  computed: {
    ...policySignMapGetters([
      "getSignedPolicyLinks",
      "getTotalRecords",
      "signedPolicies"
    ]),
    tableActions(): string[] {
      return ["Export Signed Policies", "Policy Sign Activities"];
    }
  }
});
