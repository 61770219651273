
import { get } from "lodash";
import Vue from "vue";
export default Vue.extend({
  name: "PolicyNumberFormat",
  props: {
    row: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    policyNumber(): string {
      return `${get(this.row, "policyPrefix", "")}-${get(
        this.row,
        "policyNumber",
        ""
      )}`;
    }
  }
});
