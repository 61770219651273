import { formatDate } from "@/helpers/dateProcessor";
import { ITableWrapperColumns } from "@/types";
import PolicyNumberFormat from "./PolicyNumberFormat.vue";

export const signedPoliciesTableColumns: ITableWrapperColumns = {
  agency: {
    formatDataKey: "Agency",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  policyNumber: {
    formatDataKey: "Policy Number",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    formatDataComponent: PolicyNumberFormat
  },
  modifiedOn: {
    formatDataKey: "Signed On",
    formatDataValue: (arg: string) => formatDate(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2
  },
  insuredName: {
    formatDataKey: "Insured",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3
  },
  insuredEmail: {
    formatDataKey: "Insured Email",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 4
  }
};
